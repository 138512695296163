.mytitle {
  font-size: 36px;
  text-align: left;
  flex-direction: column;
  display: flex;
  font-style: bold;
  margin-bottom: 40px;
}

div.myInfoList {
  padding: 0px;
  flex-direction: column;
  display: flex;
  margin-right: 53vh;
}

div.mylistGroup {
  margin-bottom: 20px;
  flex-direction: column;
  display: inline-block;
}

.mylabel {
  width: 180px;
  margin-right: 150px;
  font-size: 20px;
  display: inline-block;
  text-align: left;
}

.myidp {
  font-size: 20px;
  display: inline-block;
  margin-left: 10px;
  text-align: left;
  width: 500px;
}

.myinfoinput {
  display: inline-block;
  width: 500px;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding: 10px;
  font-size: 20px;
  border-radius: 2px;
}

.myFileName {
  display: inline-block;
  padding: 6px 25px;
  background-color: #fbdb6a;
  border-radius: 5px;
  color: #a98600;
  cursor: pointer;
}

.myfileinput {
  display: inline-block;
  width: 500px;
  border: none;
  padding: 10px;
  font-size: 20px;
  border-radius: 2px;
}

.myfileinput[type="file"]::file-selector-button {
  width: 100px;
  height: 50px;
  font-size: 20px;
  background-color: #fbdb6a;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
}

.myinfoinput::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.myinfoinput:focus {
  outline: 2px solid #d9d9d9;
}

.myError {
  color: red;
  margin-left: 36vh;
  font-size: 15px;
  text-align: left;
}

button.myeditInfo {
  width: 110px;
  height: 55px;
  font-size: 18px;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px;
  background-color: #fbdb6a;
  color: #a98600;
  border-radius: 20px;
  border: none;
  margin-left: 740px;
  cursor: pointer;
}

button.myeditInfo:hover {
  background-color: #ffebb4;
  color: #bf9b30;
  font-weight: bold;
}
