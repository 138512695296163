.myoffered {
  display: flex;
  position: relative;
  width: 800px;
  height: 100px;
  align-items: center;
  margin: 20px 0px;
}

.myofferedProject {
  display: flex;
  position: relative;
  width: 500px;
  height: 80px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 5px solid #ffebb4;
  border-radius: 20px;
  padding: 15px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.myofferedProject p {
  font-size: 20px;
  text-align: left;
}

button.myofferedButton {
  width: 90px;
  height: 60px;
  background-color: #fbdb6a;
  color: #a98600;
  border: none;
  border-radius: 20px;
  padding: 17.8px;
  font-size: 18px;
  display: flex;
  float: right;
  margin-left: 20px;
  margin-bottom: 13px;
  cursor: pointer;
}

button.myDMButton {
  width: 90px;
  height: 60px;
  background-color: #fbdb6a;
  color: #a98600;
  border: none;
  border-radius: 20px;
  padding: 17.8px;
  font-size: 18px;
  display: flex;
  float: right;
  margin-left: 20px;
  margin-bottom: 13px;
  cursor: pointer;
}

button.myofferedButton:hover {
  background-color: #ffebb4;
  color: #bf9b30;
  font-weight: bold;
}

button.myDMButton:hover {
  background-color: #ffebb4;
  color: #bf9b30;
  font-weight: bold;
}
