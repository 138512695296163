.mypageLayout {
  display: flex;
  position: relative;
  overflow: hidden;
  /* width: 190vh; */
}

.mywrapper {
  display: flex;
  position: relative;
  width: 80%;
  float: right;
  flex-direction: column;
  margin-left: 20vh;
  margin-top: 20px;
}

.myproject {
  width: 800px;
  height: 80px;
  margin-bottom: 20px;
  margin-right: 530px;
  align-items: center;
  border: 5px solid #ffebb4;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  position: relative;
  flex-direction: column;
  float: right;
  font-size: 20px;
  text-align: center;
}

.myfreelancer {
  width: 800px;
  height: 80px;
  margin-bottom: 20px;
  margin-right: 530px;
  align-items: center;
  border: 5px solid #ffd2b4;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  float: right;
  font-size: 20px;
  text-align: center;
}

.myh4 {
  text-align: left;
  font-size: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px;
}

button.mywithdrawal {
  box-sizing: border-box;
  text-decoration-style: bold;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 130px;
  height: 55px;
  display: flex;
  text-align: center;
  font-size: 20px;
  background-color: #fbdb6a;
  color: #a98600;
  border-radius: 20px;
  border: none;
  padding: 13px;
  margin-left: 74vh;
  cursor: pointer;
}
button.mywithdrawal:hover {
  background-color: #ffebb4;
  color: #bf9b30;
  font-weight: bold;
}
