@font-face {
    font-family: 'LINESeedKR-Rg';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Rg.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

* {
    font-family: 'LINESeedKR-Rg';
    white-space: nowrap;
}

#Layout {
    /* min-height: 100%;
    position: relative; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

#LayoutWrapper {
    /* min-height: 100%;
    position: relative;
    padding-bottom: 150px; */
    /* border: 1px solid black; */
    /* background-color: rgb(255, 188, 101); */
    min-height: calc(100vh - 150px);
    position: relative;
}

#LayoutHeader {
    height: 150px;
}

#LayoutLogo {
    position: absolute;
    margin: 15px 30px 0px 20px;
}

#bell {
    position: absolute;
    right: 220px;
    top: 15px;
}

#bell img {
    width: 55px;
    transition: transform 0.3s ease;
 }
  
  #bell:hover img {
    transform: scale(1.2);
 }

#LayoutHeader .menubar {
    position: absolute;
    width: 100%;
    height: 60px;
    top: 70px;
    left: 0px;
    background: #FBDB6A;
}

li.menu span {
    color: rgb(63, 63, 63);
    font-size: 17px;
    font-weight: bolder;
    display: block;
    height: 60px;
    text-align: center;
    padding: 5px 30px 0px;
    text-decoration: none;
}

li.menu span:hover {
    color: black;
}

li.menu {
    text-align: left;
    display: inline-block;
    text-decoration: none;
}

div .memberbtn {
    position: absolute;
    right: 0px;
    top: 10px;
}

li.member a {
    color: rgb(97, 97, 97);
    display: block;
    text-align: right;
    padding: 0px 20px 0px;
    text-decoration: none;
}

li.member a:hover {
    color: black;
}

li.member {
    display: inline-block;
    text-align: right;
}

.posts {
    position: relative;
    /* width: 75%;
    height: 100%;
    float: left;
    line-height: 40px;
    margin: 0 12% 0 12%;
    padding: 20px 0 20px 0; */
    padding-bottom: 150px;
    min-height: 60vh;
    z-index: 1;
}

#LayoutFooter {
    width: 100%;
    height: 150px;
    background: #FBDB6A;
    bottom: 0;
    position: relative;
}

#LayoutFooter td {
    font-size: bolder;
    color: rgb(62, 62, 62);
    padding: 5px;
}

#LayoutFooterCenter {
    margin: 20px;
}

.scrolling-image {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 50px;
    height: 50px;
    transform: translateY(-50%);
    z-index: 9999;
    background-size: cover;
}

.scrolling-image img {
    width: 70px;
    height: 70px;
}