

.ListSelect {
    width: 120px; height: 40px; margin: 20px 5px; padding: 5px 20px 5px;
    border: 1px solid rgb(211, 211, 211); color: #444;
}
/* 
.ListFilter {
    margin: 0 0 5px 0;
} */

.ListBox {
    width: 50%; height:fit-content; margin: 20px auto; padding: 20px;
    background-color: #ffffff; box-shadow: 0px 0px 5px #cfcfcf;
    border-radius: 20px;
}

.ListBox:hover {
    box-shadow: 0px 0px 5px #bababa;
}

.ListText {
    color: black; width: 100%; height: 100%; font-size: 16px;
}

.ListText table {
    margin: 0 auto; text-align: center; 
}

.ListText td {
    padding: 10px;
}

.ListJobTag {
    background-color: rgb(77, 77, 77); color: #ffffff;
    font-size: 14px; border-radius: 20px;
    text-align: center; padding: 7px; margin: 10px;
}

.ListPossible {
    background-color: #FBDB6A; border-radius: 20px;  color: #464646;
    text-align: center; padding: 7px; margin: 10px; font-size: 14px;
}

.ListBar { color:#cfcfcf; }

.ListIntro {
    color: black; font-weight: bold; font-size: larger;
}
.ListOption{
    width: 60%;
    margin: 0 auto;
}
.ListFilter{
    float: left;
}
.ListHr{
    clear:both;

}
.Listnew{

  text-align: right;
}
.Listnew-box{
    border: 0px;
  }
.List-search-wrapper{
    float: right;
    padding: 0 0 0 0;
    text-align: center;
    height: 69px;
}
.List-search-area{
    padding: 15px 0 0 0;
    
}
.List-search-area select{
    float: left;
    width: 106px;
    height: 40px;
    border: 1px solid #d4d4d4;
    margin-right: 4px;
    font-size: 14px;
    color: #6c6e6f;
    background-color: #fff;
    text-indent: 10px;
    background-position: calc(100% - 10px) center;
    background-size: 12px 6px;
}
.List-search-area .List-search-box{
    float:left;
}
.List-search-area .List-search-box input[type="search"] {
    float: left;
    width: 200px;
    height: 40px;
    line-height: 38px;
    border: 1px solid #d4d4d4;
    border-right: 0;
    text-indent: 10px;
    font-size: 14px;
}
.List-search-area .List-search-box input[type="submit"] {
    float: left;
    width: 92px;
    height: 40px;
    line-height: 40px;
    color: #000;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: #FBDB6A;
    border-radius: 0;
    border: 0;
}