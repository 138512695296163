.myside {
  display: flex;
  border-right: 1px solid #d9d9d9;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.mymenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  line-height: 2;
  margin-top: 15px;
  padding: 0 50px;
}

.myh6 {
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 60px;
}
